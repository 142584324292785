import { useEffect } from "react";
import { Box } from "./Box";
import { Breadcrumb } from "./Breadcrumb";
import { Buttons } from "./Buttons";
import { Cards } from "./Cards";
import { Content } from "./Content";
import { Delete } from "./Delete";
import { Dropdown } from "./Dropdown";
import { Form } from "./Form";
import { Header } from "./Header";
import { Hero } from "./Hero";
import { Icons } from "./Icons";
import { Images } from "./Images";
import { Level } from "./Level";
import { Media } from "./Media";
import { Menu } from "./Menu";
import { Message } from "./Message";
import { Modal } from "./Modal";
import { Navbar } from "./Navbar";
import { Notifications } from "./Notifications";
import { Pagination } from "./Pagination";
import { Panel } from "./Panel";
import { Progress } from "./Progress";
import { Table } from "./Table";
import { Tabs } from "./Tabs";
import { Tag } from "./Tags";
import { Typography } from "./Typography";
import type { Theme } from "types";
import { themes } from "../themes.json";

type Props = {
  theme: Theme;
  currentRoute: string;
};

export const Layout = ({ theme, currentRoute }: Props) => {
  useEffect(() => {
    const head = document.head;

    const themeLink = document.createElement("link");
    themeLink.href = `https://cdn.jsdelivr.net/npm/@markdownspace/markdownswatch@1.0.3/css/${theme.id}.css`;
    themeLink.rel = "stylesheet";
    head.appendChild(themeLink);

    document.documentElement.setAttribute("data-theme", theme.isDarkMode ? "dark" : "light");

    return () => {
      head.removeChild(themeLink);
    };
  }, [theme]);

  return (
    <html lang="en" data-theme={theme.isDarkMode ? "dark" : 'light'}>
      <head>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>{`${theme.name} | Markdownswatch`}</title>
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bulma@1.0.2/css/bulma.min.css"
        />
        <link
          rel="stylesheet"
          href={`https://cdn.jsdelivr.net/npm/@markdownspace/markdownswatch@1.0.3/css/${theme.id}.css`}
        />
      </head>
      <body>
        <Header currentRoute={currentRoute} />
        <section>
          <div className="hero is-primary">
            <div className="hero-body">
              <div className="container" style={{ padding: "0 0.75rem" }}>
                <h1 className="title">{theme.name}</h1>
                <h2 className="subtitle">{theme.description}</h2>
              </div>
            </div>
          </div>
        </section>
        <div className="container">
          <Typography />
          <Box />
          <Buttons />
          <Content />
          <Delete />
          <Form />
          <Icons />
          <Images />
          <Notifications />
          <Progress />
          <Table />
          <Tag />
          <Breadcrumb />
          <Hero />
          <Cards />
          <Dropdown />
          <Level />
          <Media />
          <Menu />
          <Message />
          <Modal />
          <Navbar />
          <Pagination />
          <Panel />
          <Tabs />
        </div>
      </body>
    </html>
  );
};