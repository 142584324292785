import { Layout } from "./components/Layout";
import themes from "./themes.json";

function App() {
  const pathname = window.location.pathname;
  const themeName = pathname.split("/")[1] || "default";
  //get theme from themes.json
  const theme = themes.themes.find((theme) => theme.id === themeName);

  return Layout({
    theme: theme,
    currentRoute: "home",
  });
}

export default App;
